import ScarsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/scars/ScarsTemplate';
import { ACNE_SCAR_CAUSE } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/Scars/ScarsTemplate'
};

const createStory = props => () => ({
  components: { ScarsTemplate },
  storyProps: props,
  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  template: `<div :style='$options.wrapStyles'>
    <scars-template v-bind="$options.storyProps" />
  </div>`
});

export const NoAnswer = createStory({
  acneScarCauses: [],
  acneScars: 'no'
});

export const YesAnswer = createStory({
  acneScarCauses: [ACNE_SCAR_CAUSE.ACNE, ACNE_SCAR_CAUSE.SURGERY, ACNE_SCAR_CAUSE.BURNS_AND_SCALDS],
  acneScars: 'yes',
  hasAcneScares: true
});
